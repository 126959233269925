export default function BedrockBaseAppIcon(): JSX.Element {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1499_16240)">
                <rect x="1" y="1" width="43" height="43" rx="9.74152" fill="white" />
                <rect x="1" y="1" width="43" height="43" rx="9.74152" fill="url(#paint0_linear_1499_16240)" />
                <path
                    d="M13.2915 28.9309C13.2915 28.9309 16.4543 33.6751 22.78 33.6751C29.1056 33.6751 32.2684 28.9309 32.2684 28.9309M13.2915 14.6982V17.8611M32.2684 14.6982V17.8611"
                    stroke="black"
                    strokeWidth="3.14101"
                    strokeLinecap="round"
                />
                <g filter="url(#filter0_f_1499_16240)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.4807 11.1735C24.4159 11.4595 27.6853 10.0967 30.1667 11.6416C32.6462 13.1853 32.9787 16.4994 34.2044 19.0951C35.315 21.4469 36.3811 23.7027 37.0449 26.2075C37.8899 29.3962 40.7353 33.2088 38.6293 35.7933C36.387 38.5453 31.3959 35.88 28.0578 37.237C25.3347 38.3439 24.3033 41.923 21.4807 42.7643C18.417 43.6774 15.0399 43.1496 12.0604 42.0048C8.8403 40.7676 4.8408 39.2551 3.9269 36.0198C2.96263 32.6061 7.47958 29.7447 7.59805 26.2075C7.71037 22.8537 3.75032 20.0158 4.57206 16.7559C5.38447 13.533 8.47974 10.9148 11.7228 9.84411C14.8856 8.79993 18.1559 10.8494 21.4807 11.1735Z"
                        fill="url(#paint1_linear_1499_16240)"
                    />
                </g>
                <g filter="url(#filter1_f_1499_16240)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M33.6384 4.53038C35.7823 4.87572 38.1703 3.23047 39.9826 5.09549C41.7936 6.95915 42.0365 10.9601 42.9318 14.0938C43.7429 16.933 44.5216 19.6564 45.0064 22.6803C45.6236 26.5299 47.7019 31.1326 46.1637 34.2528C44.5259 37.5751 40.8804 34.3575 38.4423 35.9956C36.4534 37.332 35.7001 41.6529 33.6384 42.6685C31.4007 43.7708 28.9341 43.1337 26.7579 41.7517C24.406 40.2581 21.4848 38.4321 20.8173 34.5262C20.113 30.405 23.4121 26.9505 23.4987 22.6803C23.5807 18.6313 20.6883 15.2053 21.2885 11.2698C21.8819 7.37892 24.1426 4.21815 26.5114 2.92552C28.8214 1.66492 31.21 4.13921 33.6384 4.53038Z"
                        fill="url(#paint2_linear_1499_16240)"
                    />
                </g>
                <path
                    d="M13.2915 29.3674C13.2915 29.3674 16.4543 34.1117 22.78 34.1117C29.1056 34.1117 32.2684 29.3674 32.2684 29.3674M13.2915 15.1348V18.2976M32.2684 15.1348V18.2976"
                    stroke="#1E2242"
                    strokeWidth="3.14101"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_1499_16240"
                    x="-8.05754"
                    y="-2.29924"
                    width="59.2972"
                    height="57.3778"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="5.92476" result="effect1_foregroundBlur_1499_16240" />
                </filter>
                <filter
                    id="filter1_f_1499_16240"
                    x="8.86922"
                    y="-9.27873"
                    width="49.6995"
                    height="64.3577"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="5.92476" result="effect1_foregroundBlur_1499_16240" />
                </filter>
                <linearGradient
                    id="paint0_linear_1499_16240"
                    x1="22.5"
                    y1="1"
                    x2="35.744"
                    y2="41.678"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1499_16240"
                    x1="-12.1384"
                    y1="26.5623"
                    x2="19.8958"
                    y2="60.4223"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFFA84" />
                    <stop offset="0.692708" stopColor="#FFB6EF" stopOpacity="0.29" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1499_16240"
                    x1="9.11448"
                    y1="8.94006"
                    x2="51.8182"
                    y2="29.6019"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A8FFA6" stopOpacity="0.69" />
                    <stop offset="0.692708" stopColor="#B6F2FF" stopOpacity="0.39" />
                </linearGradient>
                <clipPath id="clip0_1499_16240">
                    <rect x="1" y="1" width="43" height="43" rx="9.74152" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
